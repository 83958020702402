/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.2.3
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import { auth } from './firebase'
import VueGtag from "vue-gtag";


// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker'
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

Vue.use(VueGtag, {
  config: { id: "G-YFPBJF3E6X" }
}, router);

/* eslint-disable no-new */
let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      // store,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }
})

