var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.dashboard'),
          icon: 'tim-icons icon-sound-wave',
          path: '/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.reactors'),
          icon: 'tim-icons icon-button-power',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.reactorData'), path: '/reactordata' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.eia'), path: '/eiadata' }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.esa'), path: '/esadata' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.uranium'),
          icon: 'tim-icons icon-square-pin',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sidebar.customs'),
            path: '/customs',
          }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sidebar.inventory'), path: '/inventory' }}})],1)],1)],2),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }