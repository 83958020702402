import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';

// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');
// import Widgets from 'src/pages/Widgets.vue';

// Pages
const User = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/UserProfile.vue');
// const Pricing = () =>
//   import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Pricing.vue');
const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');
// const Register = () =>
//   import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register.vue');
// const RTL = () =>
//   import(/* webpackChunkName: "pages" */ 'src/pages/Pages/RTLPage.vue');
const Lock = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Lock.vue');

const ReactorData = () =>
  import(/* webpackChunkName: "reactors" */ 'src/pages/Reactors/ReactorData.vue');

const InventoryData = () =>
  import(/* webpackChunkName: "uranium" */ 'src/pages/Uranium/Inventory.vue');

const EiaData = () =>
  import(/* webpackChunkName: "reactors" */ 'src/pages/Reactors/EiaData.vue');

const EsaData = () =>
  import(/* webpackChunkName: "reactors" */ 'src/pages/Reactors/EsaData.vue');

const Customs = () =>
  import(/* webpackChunkName: "uranium" */ 'src/pages/Uranium/Customs.vue');


let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'Pages',
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      components: { default: User }
    },
    // {
    //   path: 'rtl',
    //   name: 'RTL Page',
    //   components: { default: RTL }
    // }
  ]
};

let reactorPages = {
  path: '/',
  component: DashboardLayout,
  name: 'Reactors',
  redirect: '/reactorData',
  children: [
    {
      path: 'reactordata',
      name: 'Reactor Data',
      components: { default: ReactorData }
    },
    {
      path: 'eiadata',
      name: 'EIA Data',
      components: { default: EiaData }
    },
    {
      path: 'esadata',
      name: 'ESA Data',
      components: { default: EsaData }
    },
  ]
};
let uraniumPages = {
  path: '/',
  component: DashboardLayout,
  name: 'Uranium',
  redirect: '/mines',
  children: [
    {
      path: 'inventory',
      name: 'Inventory',
      components: { default: InventoryData }
    },
    {
      path: 'customs',
      name: 'Customs',
      components: { default: Customs }
    },
  ]
};

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    // {
    //   path: '/register',
    //   name: 'Register',
    //   component: Register
    // },
    // {
    //   path: '/pricing',
    //   name: 'Pricing',
    //   component: Pricing
    // },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock
    }
  ]
};

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home'
  },
  pagesMenu,
  authPages,
  reactorPages,
  uraniumPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard },
        meta: {
          requiresAuth: true
        }
      },
      // {
      //   path: 'calendar',
      //   name: 'Calendar',
      //   components: { default: Calendar }
      // },
      // {
      //   path: 'charts',
      //   name: 'Charts',
      //   components: { default: Charts }
      // },
      // {
      //   path: 'widgets',
      //   name: 'Widgets',
      //   components: { default: Widgets }
      // }
    ]
  },
  { path: '*', component: NotFound }
];

export default routes;
