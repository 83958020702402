<template>
  <div class="fixed-plugin" v-click-outside="closeDropDown">
    <div class="dropdown show-dropdown" :class="{ show: isOpen }">
      <a data-toggle="dropdown" class="settings-icon">
        <i class="fa fa-cog fa-2x" @click="toggleDropDown"> </i>
      </a>
      <ul class="dropdown-menu" :class="{ show: isOpen }">
        <li class="header-title">Sidebar Background</li>
        <li class="adjustments-line">
          <a class="switch-trigger background-color">
            <div class="badge-colors text-center">
              <span
                v-for="item in sidebarColors"
                :key="item.color"
                class="badge filter"
                :class="[`badge-${item.color}`, { active: item.active }]"
                :data-color="item.color"
                @click="changeSidebarBackground(item)"
              ></span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>

        <li class="adjustments-line">
          <div class="togglebutton switch-sidebar-mini">
            <span class="label-switch">SIDEBAR MIN</span>
            <base-switch
              v-model="sidebarMini"
              @input="minimizeSidebar"
            ></base-switch>
            <span class="label-switch label-right">SIDEBAR MAX</span>
          </div>

          <div class="togglebutton switch-change-color mt-3">
            <span class="label-switch">LIGHT MODE</span>
            <base-switch v-model="darkMode" @input="toggleMode"></base-switch>
            <span class="label-switch label-right">DARK MODE</span>
          </div>
        </li>
        <li class="adjustments-line" style="padding-top: 50px">
          <div class="togglebutton switch-sidebar-mini">
            <span
              ><button
                style="
                  background: none;
                  border: none;
                  padding: 5;
                  color: #fff;
                  cursor: pointer;
                "
              >
                <h6 style="color: #fff">Profile</h6>
              </button></span
            >
          </div>
        </li>

        <li class="adjustments-line">
          <div class="togglebutton switch-sidebar-mini">
            <span
              ><button
                style="
                  background: none;
                  border: none;
                  padding: 5;
                  color: #fff;
                  cursor: pointer;
                "
                @click="logout"
              >
                <h6 style="color: #fff">Log Out</h6>
              </button></span
            >
          </div>
        </li>
        <li class="adjustments-line">
          <div class="togglebutton switch-sidebar-mini"></div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { BaseSwitch } from "src/components";
import firebase from "firebase/compat/app";

export default {
  name: "sidebar-share",
  components: {
    BaseSwitch,
  },
  props: {
    backgroundColor: String,
  },
  data() {
    return {
      sidebarMini: true,
      darkMode: true,
      isOpen: false,
      sidebarColors: [
        { color: "primary", active: false, value: "primary" },
        { color: "vue", active: true, value: "vue" },
        { color: "info", active: false, value: "blue" },
        { color: "success", active: false, value: "green" },
        { color: "warning", active: false, value: "orange" },
        { color: "danger", active: false, value: "red" },
      ],
    };
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(
          () => {
            alert("Successfully logged out");
            this.$router.push("/login");
          },
          function (error) {
            alert(error.message);
          }
        );
    },
    toggleDropDown() {
      this.isOpen = !this.isOpen;
    },
    closeDropDown() {
      this.isOpen = false;
    },
    toggleList(list, itemToActivate) {
      list.forEach((listItem) => {
        listItem.active = false;
      });
      itemToActivate.active = true;
    },
    changeSidebarBackground(item) {
      this.$emit("update:backgroundColor", item.value);
      this.toggleList(this.sidebarColors, item);
    },
    toggleMode(type) {
      let docClasses = document.body.classList;
      if (type) {
        docClasses.remove("white-content");
      } else {
        docClasses.add("white-content");
      }
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/assets/sass/dashboard/custom/variables";

.settings-icon {
  cursor: pointer;
}

.badge-vue {
  background-color: $vue;
}
</style>
