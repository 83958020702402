<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a
            href="http://uraniumtechnologies.com"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            Uranium Technologies
          </a>
        </li>
        <li class="nav-item">
          <a
            href="https://uraniumtechnologies.com/about"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            About Us
          </a>
        </li>
        <li class="nav-item">
          <a
            href="http://uraniumtechnologies.com/disclaimer"
            target="_blank"
            rel="noopener"
            class="nav-link"
          >
            Disclaimer
          </a>
        </li>
      </ul>
      <div class="copyright">&copy; {{ year }}</div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
