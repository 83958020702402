import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/analytics'

// firebase init
const firebaseConfig = {
    apiKey: "AIzaSyAdEZzYYSb4u511LTxMNxTmARTRBXrH1Fo",
    authDomain: "utech-17483.firebaseapp.com",
    databaseURL: "https://utech-17483-default-rtdb.firebaseio.com",
    projectId: "utech-17483",
    storageBucket: "utech-17483.appspot.com",
    messagingSenderId: "991439872978",
    appId: "1:991439872978:web:dea2bf1a115449cb1a5e0b",
    measurementId: "G-40R01HTCQM"
}
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const analytics = firebase.analytics();

// collection references
const currentCollection = db.collection('currentstats')
const numercoCollection = db.collection('numercoprice')
const newsCollection = db.collection('posts')
const tweetCollection = db.collection('comments')


// export utils/refs
export {
    db,
    auth,
    currentCollection,
    numercoCollection,
    newsCollection,
    tweetCollection,
    analytics,
}